import ranks from "../../../../../dbseed/ranks.json";

import "./GameRank.css";

export const GameRank = ({index, withDescription}) => {
    const rank = ranks[index-1];

    return (
        <div className="GameRank">
            {rank &&
                <>
                    <img src={`/ranks/rank_${index-1}.png`} />
                    <span className="name">{rank.name}</span>
                    {withDescription && <span className="description">{rank.desc}</span>}
                </>
            }
        </div>
    )
}

export default GameRank;