import React from "react";
import logo from "./../../images/logo.svg";
import "./PaneTop.css";

export const PaneTop = ({children}) => {
  return (
    <footer className="PaneTop">
      <div className="header">
        <img src={logo} />
        <h1>VELKÝ POLICEJNÍ KVÍZ</h1>
      </div>
      {children}
    </footer>
  );
};

export default PaneTop;