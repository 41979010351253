import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import cx from "classnames";
import "./AnswerButton.css";



export const AnswerButton = ({ index, text, onSelectAnswer }) => {
  // wish to have QBuutton withou selector, just based on props
  const [selected, select] = useState(false);

  useEffect(() =>select(false), []);

  return (
    <Button
      className={cx("AnswerButton", {
        selected
      })}
      onClick={() =>{
        select(true);
        onSelectAnswer(text, index)
      }}
    >
      {text}
    </Button>
  );
};
