import React from "react";
import { useSelector } from "react-redux";
import {
  selectGameSession,
} from "../../../../../app/reducers/gameSlice";
import GameRank from "../GameRank/GameRank";

import "./InfoTop.css";

export const InfoTop = ({className}) => {
  const { life, rank} = useSelector(selectGameSession);

  return (
    <div className={["InfoTop", className].join(" ")}>
      <GameRank index={rank}/>
      <div className="lifes">
        {"❤️".repeat(life) || ""}
      </div>
    </div>
  );
};

export default InfoTop;
