import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import React, { useCallback } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import { withRanks } from "../../../app/gameRanks";
import Loading from "../../../components/Loading/Loading";
import Controls from "../../../components/Controls/Controls";

import { Start, Back } from "../../../components/Icons";

import TableFame from "../../../components/TableFame/TableFame";
import { isAttachedToClientIp } from "../../../app/reducers/ipSlice";
import { useSelector } from "react-redux";
import { maxSessionDate } from "../../../app/utils";
import "./WallOfFame.css";

const LOAD_ALL_SESSIONS_QUERY = loader("./graphql/load-all-sessions.gql");

const createdAt_gt = maxSessionDate()

export const WallOfFame = ({ match }) => {
  const history = useHistory();
  const isPrivate = useSelector(isAttachedToClientIp);

  const onGame = useCallback(() => history.push("/hra"), [history]);
  const onHome = useCallback(() => history.push("/"), [history]);
  const { loading, data } = useQuery(LOAD_ALL_SESSIONS_QUERY, {
    variables: {
      orderBy: "answersCorrect_desc",
      filter: {
        AND: [
          { nickname_not: null },
          { isPrivate },
          { createdAt_gt }
        ],
      },
    },
    fetchPolicy: "no-cache",
  });

  if (loading) return <Loading />;

  return (
    <div className="WallOfFame">
      <div className="viewport block">
        <h2>Zeď slávy</h2>
        <TableFame>
          {withRanks(data.sessions, (session, index) => (
            <TableFame.Row key={index} {...session} />
          ))}
        </TableFame>
      </div>
      <Controls className="block">
        <Button onClick={onGame}><Start/>Začni hrát</Button>
        <Button onClick={onHome}><Back/>Zpět</Button>
      </Controls>
    </div>
  );
};

export default WallOfFame;
