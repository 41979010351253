import { useState, useCallback, useRef  } from "react";
import { Button, Table } from "react-bootstrap";
import GameRank from "../../pages/public/Game/components/GameRank/GameRank";
import "./TableFame.css"

export const TableFame = ({children}) => {
 return (
    <Table className="TableFame" striped borderless responsive="sm" size="sm">
        <thead>
        <tr>
            <th><span>Pozice</span></th>
            <th><span>Hodnost</span></th>
            <th><span>Krycí jméno</span></th>
            <th><span>Správné odpovědi</span></th>
            <th><span>Průměrný čas</span></th>
            <th><span>Ve službě od</span></th>
        </tr>
        </thead>
        <tbody>
            {children}
        </tbody>
    </Table>
 )
}

const Row = ({
    className,
    nickname,
    children,
    answersCorrect,
    avgDuration,
    createdAt,
    rank,
    position,
    positionOffset
}) => (
    <tr className={className}>
        <td className="position">{position + (positionOffset || 0)}.</td>
        <td className="rank"><GameRank index={rank}/></td>
        <td className="nickname">{nickname || children}</td>
        <td className="answers">{answersCorrect}</td>
        <td className="duration">{Math.round(avgDuration / 100) / 10 + "s"}</td>
        <td className="date">{new Date(createdAt).toLocaleDateString()}</td>
    </tr>
);

TableFame.Row = Row;


export default TableFame;