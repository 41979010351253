import { Redirect } from "react-router";
import {
  attachToIp,
  ATTACH_IP_OPTION_NAME,
} from "../../../app/reducers/ipSlice";
import { useDispatch } from "react-redux";

export const AttachToIp = () => {
  const dispatch = useDispatch();
  dispatch(attachToIp());

  return <Redirect path="/" />;
};

export default AttachToIp;
