import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/userSlice";
import ipReducer from "./reducers/ipSlice";
import gameReducer from "./reducers/gameSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    ip: ipReducer,
    game: gameReducer
  },
});
