import { useState, useEffect } from "react";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import { Button } from "react-bootstrap";

import TableFame from "../../../../../components/TableFame/TableFame";
import { isAttachedToIp } from "../../../../../app/reducers/ipSlice";
import { useSelector } from "react-redux";
import { Save } from "../../../../../components/Icons";

import "./NicknameInputRow.css";
import NicknameInputModal from "../NicknameInputModal/NicknameInputModal";

const UPDATE_SESSION_NICKNAME_MUTATION = loader(
  "../../graphql/update-session-nickname.gql"
);

const validateNickname = (nickname) =>
  String(nickname || "").replace(
    /[^a-zA-Z0-9áÁčČďĎéÉěĚíÍňŇóÓřŘšŠťŤúÚůŮýÝžŽ]/,
    ""
  );

export const NicknameInputRow = (session) => {
  const isPrivate = useSelector(isAttachedToIp);
  const [
    updateSessionNickname,
    {
      loading: updatingNickname,
      data: updatedNicknameData,
      error: updateNickNameError,
    },
  ] = useMutation(UPDATE_SESSION_NICKNAME_MUTATION);

  const [nickname, setNickname] = useState();

  const onInput = (ev) =>
    (ev.target.value = validateNickname(ev?.target?.value));

  const onChange = (ev) => setNickname(validateNickname(ev?.target?.value));

  const saveNickname = (override) =>
    updateSessionNickname({
      variables: {
        ...session,
        nickname,
        isPrivate,
        override,
      },
    });

  //controled by button key
  const overrideNickname = ({key})=>{
    if(key === 1) { saveNickname(true); }
  }

  return (
    <>
      <NicknameInputModal nickname={nickname} error={updateNickNameError} beforeClose={overrideNickname}/>
      <TableFame.Row
        {...session}
        nickname={updatedNicknameData?.session?.nickname || null}
        className="NicknameInputRow"
      >
        <div className="inputbox">
          <input
            {...{ onInput, onChange, maxLength: 7, size: 7, autoFocus: true }}
          />
          <Button onClick={()=>saveNickname(false)}><Save/>Uložit</Button>
        </div>
      </TableFame.Row>
    </>
  );
};

export default NicknameInputRow;
