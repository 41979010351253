import { Alert, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";


import Loading from "../../../../components/Loading/Loading";

import {
  selectGameRank,
  selectGameSessions,
} from "../../../../app/reducers/gameSlice";
import Controls from "../../../../components/Controls/Controls";
import TableFame from "../../../../components/TableFame/TableFame";
import NicknameInputRow from "./NicknameInputRow/NicknameInputRow";
import { withRanks } from "../../../../app/gameRanks";

import { Wof, Back } from "../../../../components/Icons";

const TableFameWithMe = ({session, sessions}) => {
  return (
    <TableFame>
      {withRanks([...sessions, session], (s, index)=>{
        if (s.position < session.position-3) { return null; }
        if (s.position > session.position+3) { return null; }
        if (s.position === session.position) {
          return <NicknameInputRow key={index} {...s} />
        } else {
          return <TableFame.Row key={index} {...s} />
        }
      })}
    </TableFame>
  )
}

const TableFameWithoutMe = ({sessions}) => {
  return (
    <TableFame>
      {sessions.slice(-7).map((s, index)=>
        <TableFame.Row key={index} {...s} />
      )}
    </TableFame>
  )
}


export const GameOver = ({ session }) => {
  const history = useHistory();

  const sessions = useSelector(selectGameSessions);

  const onHome = () => {
    history.replace("/");
  };

  const onWoF = () => {
    history.replace("/zed-slavy");
  };

  return (
    <div className="GameOver">
      <div className="viewport block">
        <h2>Konec hry</h2>
        <p>Tvá kariéra u Policie zde končí.</p>
        {
          session.answersCorrect ? 
          <TableFameWithMe {...{sessions, session}}/> : 
          <TableFameWithoutMe {...{sessions}}/>
        }
        
      </div>
      <Controls className="block">
        <Button onClick={onHome}><Back/>Zpět</Button>
        <Button onClick={onWoF}><Wof/>Zeď slávy</Button>
      </Controls>
    </div>
  );
};

export default GameOver;
