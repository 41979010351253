import { useState, useEffect } from "react";

import "./Fact.css";

export default ({index, text})=>{
  return (
    <div className="Fact">
      <p className="caption">{`Fakt #${index}`}</p>
      <p className="text">{text}</p>
    </div>
  )
}