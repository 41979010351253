import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useTick } from "../../../../../app/gameTicker";
import {
  selectGameStatus,
  selectGameSession,
} from "../../../../../app/reducers/gameSlice";
import GameRank from "../GameRank/GameRank";

import "./InfoBottom.css";

export const InfoBottom = ({className}) => {
  const status = useSelector(selectGameStatus);
  const {answersCorrect, answersWrong, answersTotal, life, rank} = useSelector(selectGameSession);

  const countdown = useRef();

  useTick(({ remain }) => {
    if (!countdown.current) { return; }
    //countdown.current.innerText = Math.round(remain / 1000) + "s";
  }, []);

  return (
    <div className={["InfoBottom", className].join(" ")}>
      <div className="correct" data-value={answersCorrect}>{answersCorrect}</div>
      <div ref={countdown} className="countdown"></div>
      <div className="wrong" data-value={answersWrong}>{answersWrong}</div>
    </div>
  );
};

export default InfoBottom;
