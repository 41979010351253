import React from "react";
import { Route, Switch } from "react-router-dom";
import WallOfFame from "./pages/public/WallOfFame/WallOfFame";
import Home from "./pages/public/Home/Home";
import Game from "./pages/public/Game/Game";
import PaneBottom from "./components/PaneBottom/PaneBottom";
import PaneTop from "./components/PaneTop/PaneTop";
import { useLocation } from "react-router-dom";
import AttachToIp from "./pages/public/AttachToIp/AttachToIp";
import trojuhelnicky from "./images/trojuhelnicky_nove.png";

import "./App.css";

export default function App() {
  const { pathname } = useLocation();
  return (
    <div className="App" data-pathname={pathname}>
      <img className="background" src={trojuhelnicky}/>
      <PaneTop/>
      <div className="Hub">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/hra" component={Game} />
          <Route exact path="/zed-slavy" component={WallOfFame} />
          <Route exact path="/attach-to-ip" component={AttachToIp} />
        </Switch>
      </div>
      <PaneBottom />
    </div>
  );
}
