import { shuffle } from "lodash";
import { Button } from "react-bootstrap";
import { useEffect, useState, useCallback, useMemo } from "react";
import { AnswerButton } from "../AnswerButton/AnswerButton";
import {
  GAME_STATUS,
  selectAnswer,
  resolveAnswer,
} from "../../../../../app/reducers/gameSlice";

import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import ticker, { useTick } from "../../../../../app/gameTicker";
import Controls from "../../../../../components/Controls/Controls";
import InfoTop from "../InfoTop/InfoTop";

import InfoBottom from "../InfoBottom/InfoBottom";
import QuestionModal from "../QuestionModal/QuestionModal";

import "./Question.css";

const ANSWER_QUESTION_MUTATION = loader("../../graphql/answer-question.gql");

export const Question = ({ question, gameStatus, session, dispatch }) => {
  const answers = useMemo(
    () => [question.a1, question.a2, question.a3, question.a4],
    [question]
  );

  const [answerQuestion, { loading: loadingAnswer, data: answerData }] =
    useMutation(ANSWER_QUESTION_MUTATION);

  const onSelectAnswer = useCallback(
    (answer, answerIndex) => {
      answerQuestion({
        variables: {
          sessionId: session?.id,
          questionId: question?.id,
          answer,
          duration: ticker.duration,
        },
      });
      dispatch(selectAnswer({ answer, answerIndex }));
    },
    [answerQuestion, answers, session, question, dispatch]
  );

  useTick(
    ({ progress }) => {
      if (progress === 1) {
        onSelectAnswer(""); //timeout
      }
    },
    [onSelectAnswer]
  );

  useEffect(() => {
    if (
      gameStatus !== GAME_STATUS.CHECKING_ANSWER ||
      loadingAnswer ||
      !answerData
    ) {
      return;
    }
    // TODO: handle error when server return question answered 2x times
    //       in this case answerData.answer=null
    dispatch(resolveAnswer(answerData));
  }, [dispatch, gameStatus, answerData, loadingAnswer]);

  return (
    <div className="Question" data-status={gameStatus}>
      <InfoTop className="block"/>
      <div className="viewport block">
        <p>{question.text}</p>
      </div>
      <InfoBottom className="block"/>
      <Controls stretch className="block">
        {answers.map((text, index) => (
          <AnswerButton
            {...{
              key: question.id + index,
              index,
              text,
              onSelectAnswer,
            }}
          />
        ))}
      </Controls>
      <QuestionModal {...{ dispatch }} />
    </div>
  );
};

export default Question;
