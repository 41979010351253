import "./Controls.css";


export const Controls = ({className, children, stretch}) => {
    return (
        <div className={["Controls", stretch ? "stretch" : "", className].join(" ")}>
            {children}
        </div>
    )

}


export default Controls;