import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { continueGame, selectGame } from "../../../../../app/reducers/gameSlice";
import GameRank from "../GameRank/GameRank";
import Fact from "../Fact/Fact";

import { Back } from "../../../../../components/Icons";
import "./QuestionModal.css";

export const QuestionModal = ({ dispatch }) => {
  const { showModal, showRank, reaction, facts, factCountdown, session } = useSelector(selectGame);

  const onClose = () => {
    dispatch(continueGame());
  };
  
  return (
    <Modal className="QuestionModal" show={showModal} centered data-status={reaction?.status} >
      <Modal.Header>
        <Modal.Title>
          {reaction?.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="content">
          <p className="reaction">{reaction?.text}</p>
          {showRank && <GameRank index={session?.rank} withDescription/>}
        </div>
        {!factCountdown && <Fact {...facts[0]}/>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={showModal ? onClose : null}>
          <Back/>Pokračovat
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default QuestionModal;
