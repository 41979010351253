import { useEffect } from "react";

const priv = {
  id:null,
  started:null,
  stopped:null,
  timeout:null,
  callbacks:[],
  run:()=>{
    priv.callbacks.forEach(f=>f(ticker));
  }
}

const ticker = Object.defineProperties({
  reset:()=>{
    if (priv.started === null) { return; }
    clearInterval(priv.id);
    priv.id = null;
    priv.started = null;
    priv.timeout = null;
    priv.stopped = null;
    priv.run();
  },
  stop:()=>{
    if (priv.id === null) { return; }
    clearInterval(priv.id);
    priv.id = null;
    priv.stopped = Date.now();
    priv.run();
  },
  start:(timeout)=>{
    if (priv.id !== null) { return; }
    priv.started = Date.now();
    priv.timeout = Date.now()+timeout;
    priv.stopped = null;
    priv.run();
    priv.id = setInterval(()=>{
      if (Date.now() >= priv.timeout) { ticker.stop(); }
      else { priv.run(); }
    }, 50);
  },
  onTick:(callback)=>{
    priv.callbacks.push(callback);
    return () =>{
      const index = priv.callbacks.indexOf(callback);
      if (index >= 0) { priv.callbacks.splice(index, 1); }
    }
  }
}, {
  running:{get:()=>priv.started !== null && priv.stopped === null},
  started:{get:()=>priv.started === null ? Date.now() : priv.started},
  stopped:{get:()=>priv.stopped === null ? Date.now() : Math.min(priv.stopped, priv.timeout)},
  timeout:{get:()=>priv.timeout === null ? Date.now() : priv.timeout},
  duration:{get:()=>ticker.stopped - ticker.started},
  remain:{get:()=>ticker.length - ticker.duration},
  length:{get:()=>ticker.timeout - ticker.started},
  progress:{get:()=>ticker.length ? ticker.duration / ticker.length : 0},
  degress:{get:()=>ticker.length ? ticker.remain / ticker.length : 1},
});

export const useTick = (callback, deps) =>useEffect(()=>ticker.onTick(callback), deps)

export default ticker;