import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import logo from "../../../images/logo.svg";
import { Start, Wof } from "../../../components/Icons";

import Controls from "../../../components/Controls/Controls";

import "./Home.css";

export const Home = () => {
  const history = useHistory();

  const onGame = () => history.replace("/hra");
  const onWoF = () => history.push("/zed-slavy");

  return (
    <div className="Home">
      <div className="viewport">
        <h2>VELKÝ POLICEJNÍ KVÍZ</h2>
        <img src={logo} alt="Policie ČR"/>
      </div>
      <Controls className="block">
        <Button onClick={onGame}><Start/>Začni hrát</Button>
        <Button onClick={onWoF}><Wof/>Zeď slávy</Button>
      </Controls>
    </div>
  );
};

export default Home;