import { createSlice } from "@reduxjs/toolkit";

export const ATTACH_IP_OPTION_NAME = "attach.ip";
const initialState = {
  attached: false,
};

export const ip = createSlice({
  name: "school",
  initialState,
  reducers: {
    attachToIp: (state) => {
      state.attached = true;
    },
  },
});

export const isAttachedToIp = (state) => state?.ip?.attached;
// Action creators are generated for each case reducer function
export const { attachToIp } = ip.actions;

export const isAttachedToClientIp = (state) => state?.ip?.attached;

export default ip.reducer;
