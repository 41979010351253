
import ranks from "../dbseed/ranks.json";

const DECAY_RATE = 2;


const compare = (a, b)=>{
    if (a.answersCorrect != b.answersCorrect) { return b.answersCorrect-a.answersCorrect; }
    if (a.avgDuration != b.avgDuration) { return a.avgDuration-b.avgDuration; }
    return a.createdAt-b.createdAt;
};

const calculateRank = (index, length, answersCorrect)=>{
    if (!answersCorrect) { return 0; }
    const progress = 1-(index/(length-1));
    if (index === 0) { return ranks.length; }
    return 1+Math.round(Math.pow(progress, DECAY_RATE)*(ranks.length-2));
}
  
export const withRanks = (sessions, onSession)=>[...sessions].sort(compare).map((session, index)=>{
    session = { ...session, position:index+1, rank:calculateRank(index, sessions.length, session.answersCorrect) }
    return onSession ? onSession(session, index) : session;
});

export const withRank = (session, sessions)=>{
    const { position } = session;
    let index = Math.max(0, position != null ? position-1 : sessions.length);
    while (true) {
        if (index <= 0) { break; }
        if (compare(sessions[index-1], session)<0) { break; }
        index--;
    }
    session.position = index+1;
    session.rank = calculateRank(index, sessions.length+1, session.answersCorrect);
    return session;
}