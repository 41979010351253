import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { shuffle, startCase, assign } from "lodash";
import { withRanks, withRank } from "../gameRanks";

import facts from "../../dbseed/facts.json";
import reactions from "../../dbseed/reactions.json";

const FACT_COUNTDOWN = 3;

export const GAME_STATUS = {
  OFF: "OFF",
  QUESTION: "QUESTION",
  TIMEOUT: "TIMEOUT",
  CHECKING_ANSWER: "CHECKING_ANSWER",
  WRONG_ANSWER: "WRONG_ANSWER",
  CORRECT_ANSWER: "CORRECT_ANSWER",
  CORRECT_ANSWER_RANK: "CORRECT_ANSWER_RANK",
  CORRECT_ANSWER_FIRST_RANK: "CORRECT_ANSWER_FIRST_RANK",
  GAMEOVER: "GAMEOVER",
};

const doInitGame = () => {
  return {
    status: GAME_STATUS.OFF,
    sessions: [],
    questions: [],
    questionIndex: 0,
    reactions: [],
    facts: [],
    factCountdown:FACT_COUNTDOWN,
    session: {},
    reaction: null,
    fact: null,
    answerIndex: null,
    showModal:false,
    showRank:false
  };
};

const initialState = {
  ...doInitGame(),
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    exitGame: (state, action) => {
      console.log("exitGame", action);
      return doInitGame();
    },
    startGame: (state, action) => {
      console.log("startGame", action);
      const { session, questions, sessions } = action.payload;
      return {
        ...doInitGame(session.id),
        status: GAME_STATUS.QUESTION,
        questions: shuffle(questions),
        sessions: withRanks(sessions),
        reactions: shuffle(reactions),
        facts: shuffle(facts),
        position: sessions.length + 1,
        session,
      };
    },
    continueGame: (state, action) => {
      console.log("continueGame", action);
      state.questionIndex += 1;

      if (
        state.session.life > 0 &&
        state.questionIndex < state.questions?.length
      ) {
        state.status = GAME_STATUS.QUESTION;
      } else {
        state.status = GAME_STATUS.GAMEOVER;
      }

      state.showModal = false;
    },
    selectAnswer: (state, action) => {
      const { answer, answerIndex } = action.payload;
      state.answerIndex = answerIndex;
      state.status = GAME_STATUS.CHECKING_ANSWER;
    },
    resolveAnswer: (state, action) => {
      const { isCorrect, session } = action.payload?.answer;
      const rank = state.session.rank;
      const facts = current(state.facts);

      state.session = withRank(session, state?.sessions);
      state.showRank = false;

      if (!state.factCountdown) {
        state.factCountdown = FACT_COUNTDOWN;
        state.facts = [...facts.slice(1), facts[0]];
      }

      if (state.answerIndex == null) {
        state.status = GAME_STATUS.TIMEOUT;
      } else if (!isCorrect) {
        state.status = GAME_STATUS.WRONG_ANSWER;
        state.factCountdown --;
      } else if (!rank || state.session.rank !== rank) {
        state.status = rank ? GAME_STATUS.CORRECT_ANSWER_RANK : GAME_STATUS.CORRECT_ANSWER_FIRST_RANK;
        state.factCountdown = FACT_COUNTDOWN;
        state.showRank = true;
      } else {
        state.status = GAME_STATUS.CORRECT_ANSWER;
        state.factCountdown --;
      }
      
      state.reaction = shuffle(reactions).reduce((r, v)=>v.status===state.status ? v : r);
      state.showModal = !!state.reaction;

    },
  },
});

export const selectGame = (state) => state?.game;
export const selectGameStatus = (state) => state?.game?.status;
export const selectGameQuestions = (state) => state?.game?.questions;
export const selectGameQuestionIndex = (state) => state?.game?.questionIndex;
export const selectGameCurrentQuestion = (state) =>
  state?.game?.questions[state?.game?.questionIndex];
export const selectGameReaction = (state) => state?.game?.reaction;
export const selectGameSessions = (state) => state?.game?.sessions;
export const selectGameSession = (state) => state?.game?.session;
export const selectGameSessionId = (state) => state?.game?.session?.id; // session?.id
export const selectGameLife = (state) => state?.game?.session?.life;
export const selectGameTotalAnswers = (state) =>
  state?.game?.session?.answersTotal;
export const selectGameCorrectAnswers = (state) =>
  state?.game?.session?.answersCorrect;
export const selectGameRank = (state) => state?.game?.session?.rank;

// Action creators are generated for each case reducer function
export const {
  startGame,
  exitGame,
  continueGame,
  selectAnswer,
  resolveAnswer,
} = gameSlice.actions;

export default gameSlice.reducer;
