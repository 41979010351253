import React, { useRef } from "react";
import { useTick } from "../../app/gameTicker";


import "./PaneBottom.css";


export const PaneBottom = ({ children }) => {

  const burner = useRef();
  const tunnel = useRef();

  useTick(({running, degress})=>{
    
    const alert = Math.min(1, degress*3);
    const color = `rgb(253, ${207*alert}, 0)`;
    if (burner.current) {
      burner.current.style.width = (degress * 100) + "%";
      burner.current.style["background-color"] = color;
    }
    if (tunnel.current) {
      tunnel.current.style.display = (running && alert < 1) ? "block" : "none;";
      tunnel.current.style.opacity = 1-alert;
    }

  }, []);

  return (
    <header className="PaneBottom">
      <div ref={burner} className="burner"/>
      <div className="ribbon">
        {children}
      </div>
      <div ref={tunnel} className="tunnel"/>
    </header>
  );
};

export default PaneBottom;