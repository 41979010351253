import { useState, useEffect, useCallback, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useMemo } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  GAME_STATUS,
  selectGameCurrentQuestion,
  selectGameSession,
  selectGameStatus,
} from "../../../../app/reducers/gameSlice";

import Question from "./Question/Question";
import GameOver from "./GameOver";
import ticker from "../../../../app/gameTicker";

const TIMER_LENGTH = 20000;


export const GameView = ({ dispatch }) => {
  const gameStatus = useSelector(selectGameStatus);
  const question = useSelector(selectGameCurrentQuestion);
  const session = useSelector(selectGameSession);

  useEffect(()=>{
    if (gameStatus === GAME_STATUS.GAMEOVER) { ticker.reset(); }
    else if (gameStatus !== GAME_STATUS.QUESTION) { ticker.stop(); }
    else { ticker.start(TIMER_LENGTH); }
  }, [gameStatus]);

  //reset ticker on componentDidUnmount
  useEffect(() => () => ticker.reset(), []);

  if (gameStatus === GAME_STATUS.GAMEOVER)
    return <GameOver {...{session, gameStatus}} />;

  return (
    <>
      {question && dispatch && (
        <Question {...{session, gameStatus, question, dispatch}}/>
      )}
    </>
  );
};

export default GameView;
