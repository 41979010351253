import React from "react";


import { Write, Back, Wof } from "../../../../../components/Icons";
import SimpleModal from "../../../../../components/SimpleModal/SimpleModal";

import "./NicknameInputModal.css";

const NicknameInvalid = (props) => {
  return (
    <SimpleModal {...props} title="Neplatné jméno" buttons={[<><Back/>Zpět</>]}>
        <p>Krycí jméno nelze použít, zkus nějaké jiné.</p>
    </SimpleModal>
  )
}

const NicknameHigher = (props) => {
  return (
    <SimpleModal {...props} title="Obsazené jméno" buttons={[<><Back/>Zpět</>]}>
        <p>Krycí jméno '<i>{props.nickname}</i>' je obsazeno hráčem s vyšší hodností.</p>
    </SimpleModal>
  )
}

const NicknameLower = (props) => {
  return (
    <SimpleModal {...props} title="Obsazené jméno" buttons={[<><Back/>Zpět</>, <><Wof/>Přepsat</>]}>
        <p>Krycí jméno '<i>{props.nickname}</i>' je obsazeno hráčem s nižší hodností.</p>
    </SimpleModal>
  )
}

const UnknownError = (props) => {
  const { error } = props;
  return (
    <SimpleModal {...props} title="Krycí jméno selhalo" buttons={[<><Back/>Zpět</>]}>
        <p>Neznámá chyba</p>
        <i>{error.toString()}</i>
    </SimpleModal>
  )
}


export const NicknameInputModal = (props) => {
  const { error } = props;
  if (!error) { return null; }

  const str = error.toString();

  if (str === "Error: This name is not allowed")
    return <NicknameInvalid {...props} showDep={error}/>
  if (str === "Error: This name is already taken with higher rank")
    return <NicknameHigher {...props} showDep={error}/>
  if (str === "Error: Nickname exist but can be overrided")
    return <NicknameLower {...props} showDep={error}/>

  return <UnknownError {...props} showDep={error}/>
};

export default NicknameInputModal;
