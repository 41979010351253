import { useMutation, useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { useEffect } from "react";
import Loading from "../../../components/Loading/Loading";
import GameView from "./components/GameView";
import { startGame, exitGame } from "../../../app/reducers/gameSlice";
import { useDispatch, useSelector } from "react-redux";

import { isAttachedToClientIp } from "../../../app/reducers/ipSlice";
import { maxSessionDate } from "../../../app/utils";

const CREATE_GAME_SESSION_MUTATION = loader("./graphql/create-session.gql");
const LOAD_QUIESTIONS_AND_SESSIONS_QUERY = loader(
  "./graphql/load-questions.gql"
);
const createdAt_gt = maxSessionDate()
export const Game = () => {
  const dispatch = useDispatch();
  const isPrivate = useSelector(isAttachedToClientIp);

  // PREPARE GAME
  const { data: dataQuestions } = useQuery(LOAD_QUIESTIONS_AND_SESSIONS_QUERY, {
    variables: {
      isPrivate,
      createdAt_gt,
    },
    fetchPolicy: "no-cache",
  });
  const [createGameSession, { data: dataCreateSession }] = useMutation(
    CREATE_GAME_SESSION_MUTATION,
    { variables: { isPrivate } }
  );

  const { session } = dataCreateSession || {};
  const { questions, sessions } = dataQuestions || {};

  const loading = !session || !questions || !sessions;

  useEffect(() => {
    createGameSession();
  }, [createGameSession]);

  useEffect(() => {
    if (loading) {
      return;
    }
    dispatch(startGame({ session, questions, sessions }));
    return () => dispatch(exitGame());
  }, [dispatch, loading, dataQuestions, dataCreateSession]);

  // GAME READY!?
  if (loading) {
    return <Loading what={"Kvíz pro Tebe se připravuje..."} />;
  }

  return (
    <>
      <GameView {...{ dispatch }} />
    </>
  );
};
export default Game;
