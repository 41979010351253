import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";

import "./SimpleModal.css";

export const SimpleModal = ({className, showDep, title, children, buttons, beforeClose}) => {

  const [pending, setPending] = useState(false);

  useEffect(()=>{
    if (!!showDep) {setPending(true);}
  }, [showDep]);

  const onClose = useCallback((...a)=>{
    if (!beforeClose || beforeClose(...a) !== false) { setPending(false); }
  }, [setPending, beforeClose]);

  return (
    <Modal className={className} show={pending} centered >
      <Modal.Header>
        <Modal.Title>
            {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        {buttons.map((button, key)=>
          <Button key={key} onClick={(event)=>onClose({event, button, key})}>
            {button}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default SimpleModal;